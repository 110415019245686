// FIND A broker
$(function() {
    $('#bh-sl-map-container').storeLocator({
        'originMarker': false,
        'originMarkerImg': '/webroot/img/brokers/map--pin.png',
        'originMarkerDim': {
            'width': 29,
            'height': 37,
        },
        'bounceMarker': true,
        'storeLimit' : 6,
        'dataType': 'xml',
        'dataLocation' : '/home-insurance/broker-finder/locations.php',
        'infowindowTemplatePath' : '/home-insurance/broker-finder/infowindow-description.html',
        'listTemplatePath' : '/home-insurance/broker-finder/location-list-description.html',
        'mapSettings' : {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom : 9,
        },
        'listColor2': "#ffffff",
        'pagination': true,
        'locationsPerPage': 3,
        'nextPage' : 'Next',
        'prevPage' : 'Prev',
        'debug':false,
        // 'slideMap': false,
        'fullMapStart': false,
        /*'fullMapStart': true,
        'fullMapStartListLimit' : 3,*/
        /*'dragSearch': true,
        'visibleMarkersList': true*/
    });
});
//
